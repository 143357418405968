header{
    height: 100vh;
    padding-top: 4rem;
    overflow: hidden;
}

.header_container {
    text-align: center;
    height: 100%;
    position: relative;
    
}

.me {
    position: absolute;
    left: 50%;
    top: 65%;
    transform: translate(-50%, -50%); 
    height: 12vw;
 
}
/* CTA BUTTON */
.cta{
    margin-top: 1.6rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;

}



/* socials */
.header_socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 1.3rem;
    bottom: 2rem;
    font-size: 25px;
    
    

}
.header_socials a {
    color: white;

}
.header_socials a:hover {
    color: rgba(187, 30, 56, 0.692);

}
/* image adjusting in ME */

.me-image {
    width: 17vw; 
    height: 17vw; 
  }
  

/*scroll down link position */

.scroll_down {
    position: absolute;
    right: -1.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 22px;
    color: white;
}
.scroll_down:hover {
   color: rgba(187, 30, 56, 0.692);
}

/* Binary animation */
.circle {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.text{
    position: absolute;
    width: 100% ;
    height: 100%;
    animation: rotateText 10s linear infinite;
    color: white;
    transform-origin: center;

}

@keyframes rotateText
{
    0%
    {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.white-circle {
    position: absolute;
    width: 170%; /* Adjust the size as needed */
    height: 170%; /* Adjust the size as needed */
    background-color: #fff3fb;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
.text span {
    position: absolute;
    font-size: 2.3rem;
    transform-origin: 0 100px;
    width: 350px;
  pointer-events: none;
    
}

@media screen and (max-width: 1024px) {
    header {
      height: 55vh;
    }
  
    .me {
      position: relative;
      left: 48%;
      top: 50%;
      width: 200px; 
      height: 500px; 

    }

    .me-image {
        width: 40vw; 
        height: 40vw; 
      }
      
    .text span {
        position: absolute;
        font-size: 3rem;
        transform-origin: 0 100px;
        width: 48vw;
        pointer-events: none;

    }

    .cta{
        margin-top: 1.6rem;
        display: flex;
        gap: 1.2rem;
        justify-content: center;
        font-size: 25px;
     
    }

    .white-circle {
        position: absolute;
        width: 225%; 
        height: 225%; 
        background-color: #fff3fb;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }

    .header_socials {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.8rem;
        position: absolute;
        left: 0.2rem;
        bottom: 2rem;
        font-size: 37px;
    
    
    }
    .scroll_down {
        position: absolute;
        right: -5rem;
        bottom: 5rem;
        transform: rotate(90deg);
        font-weight: 300;
        font-size: 30px;
        color: white;
    }
      
    .header_content{
        font-size: 20px;
    }
  }
  
  /* for smaller devices */
  @media screen and (max-width: 600px) {
    .me {
      position: relative;
      width: 100%;
      height: 100%;
     
    }
  
  }