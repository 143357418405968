.experience_container{
    display: grid;
    grid-template-columns: 1fr;    
    gap: 4rem;
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    justify-content: center;
    align-items: center;
 
}

.experience_container > div {
    
    background: var(--color-white);
    padding: 2.4rem 5rem;
    border-radius: 4rem;
    border: 1px solid transparent;  
    width: 48%;
    right: 50%;
     
}
.experience_container > div:hover {
    
    background: rgba(247, 228, 240, 0.771);
    border-color: transparent;
    cursor: default;
    
}


.experience_container > div h3 {
 
    text-align: center;
    margin-bottom: 2rem;
    
    color: var(--color-primary-varient);
    
    
}

.experience_content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
    column-gap: 3rem;
    
    
    
}

.experience_details {
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;

}

.experience_details-icon {
    margin-top: 6px;
    color:var(--color-primary-varient);
}
/* Media Queries medium devices */ 
@media screen and (max-width: 1024px) {
    .experience_container{
        grid-template-columns: 1fr;
     
    }

    .experience_container > div {
        width: 100%;
        padding: 2rem;
        margin: 0 auto;
    }

    .experience_content {
        padding: 1rem;
    }
}
/* Media Queries small devices */
@media screen and (max-width: 600px) {
    .experience_container{
        gap: 1rem;
    }

    .experience_container > div {
        width: 100%;
        padding: 2rem 1rem;

    }
}