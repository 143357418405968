

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    
}

:root {
    --color-bg: linear-gradient(#eadee5);
    --color-primary: rgba(247, 228, 240, 0.771);
    --color-primary-variant:#edb5e18d;
    --color-white: rgb(248, 241, 241);
    --color-light: rgba(187, 30, 56, 0.692);
    --color-text: #000000;
    --color-header: #fbebf2;

    --transition: all 400ms ease;

    --containter-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
 
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar{
    display: none;
}
body {
    font-family: 'Raleway', sans-serif;
    line-height:1.7;
    background-size: 100%;
  background-color: #b4b3f4;
   background-image: linear-gradient(326deg,#fbc5d3, #b4b3f4, #fbc7d4);
 
  
    
    
}

.container {
    width: var(--container-width-lg);
    margin: 0 auto;

}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 500;
}


h1 {
    font-size: 3.5rem; 
}
section {
    margin-top: 8rem;
    
}

section > h2, section > h4 {
    text-align: center;
    color: var(--color-light);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;  
}
.text-light {
    color:var(--color-light);

}
.text-new {
    color: var(--color-white);
}

.text-trans{
    color: transparent;
}

.text-normal{
    color: var(--color-text);
}
a {
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover {
    color: var(--color-white);
}

.btn{
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition:var(--transition);
}

.btn:hover {
    background: var(--color-primary-variant);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary {
    background: var(--color-primary);
    color: var(--color-bg);
}

img {
    display: block;
    width:100%;
    object-fit: cover;
}

/* following code is required for interface to work on different platforms medium */
@media screen and (max-width:1024px) {
    .container {
        width: var(--container-width-md)
    }
    section {
        margin-top: 6rem; 
    
    }
}
/* following code is required for interface to work on different platforms small  */
@media screen and (max-width:1024px) {
    .container {
        width: var(--container-width-sm)
    }
    section {
        margin-bottom: 2rem; 
    
    }
}